import Vue from 'vue';
import Vuex from 'vuex';
import Api from '../api'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false, // 全局加载中效果
    showDetail: false, // 支付界面展示控制
    showStatus: false, // 展示状态界面
    orderData: {}, // 当前订单相关数据
    payType: '', // 当前支付类型
    remainTime: '', // 当前订单剩余处理时间
    hasExpired: 0, // 订单是否已经失效
    dataTimer: '', // 轮询计时器
    payTypeList: [
      {
        key: 'USDT',
        name: 'USDT',
        keyLabel: 'USDT',
        currencyLabel: 'USDT',
        icon: '/mediacryptos/usdt.png',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_usdt',
        order: 1,
        networks: [
          {
            key: 'P2P_USDT_TRC20',
            label: 'Trc20',
            currencyLabel: 'USDT',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/TRON.png',
            permissionKey: 'accept_usdt_trc20',
            show: true,
          },
          {
            key: 'P2P_USDT_ERC20',
            label: 'Erc20',
            currencyLabel: 'USDT',
            icon: '/mediacryptos/eth.png',
            permissionKey: 'accept_usdt_erc20',
            show: true,
          },
          {
            key: 'USDT_ARB',
            label: 'Arbitrum',
            currencyLabel: 'USDT',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/ARB.svg',
            permissionKey: 'accept_usdt_arb',
            show: true,
          },
          {
            key: 'USDT_CELO',
            label: 'Celo',
            currencyLabel: 'USDT',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/celo.png',
            permissionKey: 'accept_usdt_celo',
            show: true,
          },
          {
            key: 'USDT_BNB',
            label: 'Binance',
            currencyLabel: 'USDT',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/bnb.png',
            permissionKey: 'accept_usdt_bnb',
            show: true,
          },
          {
            key: 'USDT_TON',
            label: 'TON',
            currencyLabel: 'USDT',
            icon: '/mediacryptos/ton.png',
            permissionKey: 'accept_usdt_ton',
            show: true,
          }
        ]
      },
      {
        key: 'USDC',
        name: 'USDC',
        keyLabel: 'USDC',
        currencyLabel: 'USDC',
        icon: '/mediacryptos/usdc.png',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_usdc',
        order: 2,
        networks: [
          {
            key: 'USDC',
            label: 'Erc20',
            currencyLabel: 'USDC',
            icon: '/mediacryptos/eth.png',
            permissionKey: 'accept_usdc',
            show: true,
          },
          {
            key: 'USDC_ARB',
            label: 'Arbitrum',
            currencyLabel: 'USDC',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/ARB.svg',
            permissionKey: 'accept_usdc_arb',
            show: true,
          },
          {
            key: 'USDC_SOL',
            label: 'Solana',
            currencyLabel: 'USDC',
            icon: '/mediacryptos/sol.png',
            permissionKey: 'accept_usdc_sol',
            show: true,
          },
          {
            key: 'USDC_BASE',
            label: 'Base',
            currencyLabel: 'USDC',
            icon: '/mediacryptos/base.svg',
            permissionKey: 'accept_usdc_base',
            show: true,
          },
          {
            key: 'USDC_POL',
            label: 'Polygon',
            currencyLabel: 'USDC',
            icon: '/mediacryptos/pol.svg',
            permissionKey: 'accept_usdc_pol',
            show: true,
          },
        ]
      },
      {
        key: 'ETH',
        name: 'Ethereum',
        keyLabel: 'ETH',
        label: 'ETH',
        currencyLabel: 'ETH',
        icon: '/mediacryptos/eth.png',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_eth',
        order: 3,
        networks: [
          {
            key: 'P2P_ETH',
            label: 'Erc20',
            currencyLabel: 'ETH',
            icon: '/mediacryptos/eth.png',
            permissionKey: 'accept_eth',
            show: true,
          },
          {
            key: 'ETH_ARB',
            label: 'Arbitrum',
            currencyLabel: 'ETH',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/ARB.svg',
            permissionKey: 'accept_eth_arb',
            show: true,
          },
          {
            key: 'ETH_BASE',
            label: 'Base',
            currencyLabel: 'ETH',
            icon: '/mediacryptos/base.svg',
            permissionKey: 'accept_eth_base',
            show: true,
          },
        ]
      },
      {
        key: 'CUSD_CELO',
        name: 'cUSD (CELO)',
        keyLabel: 'CUSD_CELO',
        label: 'Celo',
        currencyLabel: 'cUSD',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        icon: '/mediacryptos/cusd.png',
        permissionKey: 'accept_cusd_celo',
        order: 4,
      },
      {
        key: 'ICP',
        name: 'ICP',
        keyLabel: 'ICP',
        currencyLabel: 'ICP',
        icon: 'https://dcdn.mugglepay.com/pay/media/icons/icp.svg',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_icp',
        order: 5,
        networks: [
          {
            key: 'ICP',
            label: 'ICP',
            currencyLabel: 'ICP',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/icp.svg',
            permissionKey: 'accept_icp',
            show: true,
          },
          {
            key: 'CKBTC',
            label: 'ckBTC',
            currencyLabel: 'ckBTC',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/ckbtc.png',
            permissionKey: 'accept_ckbtc',
            show: true,
          },
          {
            key: 'CKETH',
            label: 'ckETH',
            currencyLabel: 'ckETH',
            icon: 'https://dcdn.mugglepay.com/pay/media/icons/cketh.png',
            permissionKey: 'accept_cketh',
            show: true,
          },
          {
            key: 'CKUSDC',
            label: 'ckUSDC',
            currencyLabel: 'ckUSDC',
            icon: '/mediacryptos/ckusdc.png',
            permissionKey: 'accept_ckusdc',
            show: true,
          },
        ]
      },
      {
        key: 'EURT',
        name: 'EURT',
        keyLabel: 'EURT',
        label: 'EURT',
        currencyLabel: 'EURT',
        icon: 'https://dcdn.mugglepay.com/pay/media/icons/EURT.png?t=1',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_eurt',
        order: 6
      },
      {
        key: 'XAUT',
        name: 'XAUT',
        keyLabel: 'XAUT',
        label: 'XAUT',
        currencyLabel: 'XAUT',
        icon: 'https://dcdn.mugglepay.com/pay/media/icons/XAUT.png',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_xaut',
        order: 7
      },
      {
        key: 'USDV_ARB',
        name: 'USDV (Arbitrum)',
        keyLabel: 'USDV_ARB',
        label: 'Arbitrum',
        currencyLabel: 'USDV',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        icon: '/mediacryptos/usdv_arb.png',
        permissionKey: 'accept_usdv_arb',
        order: 8,
      },
      {
        key: 'USDCE_POL',
        name: 'USDC.e (PoS)',
        keyLabel: 'USDCE_POL',
        label: 'Polygon',
        currencyLabel: 'USDC.e',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        icon: '/mediacryptos/usdce.png',
        permissionKey: 'accept_usdce_pol',
        order: 9,
      },
      {
        key: 'PYUSD_SOL',
        name: 'PYUSD (Solana)',
        keyLabel: 'PYUSD_SOL',
        label: 'Solana',
        currencyLabel: 'PYUSD',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        icon: '/mediacryptos/pyusd_sol.png',
        permissionKey: 'accept_pyusd_sol',
        order: 10,
      },
      {
        key: 'SOL',
        name: 'Solana',
        keyLabel: 'SOL',
        currencyLabel: 'SOL',
        label: 'Solana',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        icon: '/mediacryptos/sol.png',
        permissionKey: 'accept_sol',
        order: 11,
      },
      {
        key: 'TON',
        name: 'TON Networks',
        keyLabel: 'TON',
        label: 'TON',
        currencyLabel: 'TON',
        icon: '/mediacryptos/ton.png',
        time: '',
        describeTextPre: '1-5 ',
        describeText: 'minutes',
        show: true,
        permissionKey: 'accept_ton',
        order: 12,
        networks: [
          {
            key: 'TON',
            label: 'TON',
            currencyLabel: 'TON',
            icon: '/mediacryptos/ton.png',
            permissionKey: 'accept_ton',
            show: true,
          },
          {
            key: 'NOT',
            label: 'Notcoin',
            currencyLabel: 'NOT',
            icon: '/mediacryptos/not.png',
            permissionKey: 'accept_not',
            show: true,
          },
        ]
      },
    ], // 币种列表
    appBrand: {
      url: 'https://mugglespublicweb.s3.ap-southeast-1.amazonaws.com/imgs/mugglepay_logo.jpg',
      text: 'MugglePay',
      email: 'business@mugglepay.com',
      website: 'https://www.mugglepay.com/'
    }
  },
  mutations: {
    setAppBrand(state, value) {
      state.appBrand = value
    },
    setPayType(state, value) {
      state.payType = value
    },
    handleDetailPageStatus(state, value) {
      state.showDetail = value
    },
    setOrder(state, value) {
      state.payType = value.invoice && value.invoice.pay_currency || ''
      state.orderData = value
    },
    showStatus(state, value) {
      state.showStatus = value
    },
    addLoading(state, value) {
      state.loading = 1
    },
    removeLoading(state, value) {
      state.loading = 0
    },
    setRemainTime(state, value) {
      state.remainTime = value
    },
    setExpired(state, value) {
      state.hasExpired = 1
    },
    // 根据当前支付类型状态展示列表
    setListStatus(state, value) {
      let lists = state.payTypeList
      if (value) {
        lists.forEach((payObj) => {
          if (payObj.hasOwnProperty('networks') && payObj.networks.length > 0) {
            payObj['networks'] = payObj.networks.map((net) => {
              let permissionkey = net['permissionKey']
              net['show'] = value.hasOwnProperty(permissionkey) && value[permissionkey] !== false;

              return net;
            });

            const showNet = payObj['networks'].filter((net) => net['show']);
            payObj['show'] = showNet.length > 0;
          } else {
            let permissionkey = payObj['permissionKey']
            payObj['show'] = value.hasOwnProperty(permissionkey) && value[permissionkey] !== false
            // 微信支付宝特殊处理
            if (['ALIPAY', 'WECHAT'].includes(payObj.key)) {
              payObj['show'] = value.hasOwnProperty(permissionkey) && (value[permissionkey] !== false && value.permission.includes([payObj.key]))
            }
          }
        })
      }
    }
  },
  actions: {
    getAppBrand(store, domain) {
      return Api.getAppBrand(domain)
    },
    getOrderData(store, order) {
      return Api.getOrderDetail(order)
    },
    sendEmailData(store, data) {
      return Api.sendEmail(data.emailText, data.order)
    },
    verifyCoupon(store, data) {
      return Api.verifyCoupon(data.coupon, data.order)
    }
  },
  modules: {},
});
