// https://stackoverflow.com/questions/69874762/how-to-send-usdc-spl-using-solana-labs-web3-js

import {
  createTransferCheckedInstruction,
  getAssociatedTokenAddress,
  TOKEN_2022_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddressSync
} from "@solana/spl-token";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import BigNumber from "bignumber.js";

import * as buffer from "buffer";

if (typeof window.Buffer === "undefined") {
  window.Buffer = buffer.Buffer;
}

// Mainnet USDC, uncomment if using mainnet
const USDC_ADDRESS = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
const PYUSD_ADDRESS = new PublicKey("2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo");

// Connection endpoint, switch to a mainnet RPC if using mainnet
const ENDPOINT = process.env.VUE_APP_SOLANA_MAINNET || "https://solana-mainnet.g.alchemy.com/v2/qHxUD_nrF3aUgKkCNTf7Sg_u0xCV2HDL";
// const ENDPOINT = "https://go.getblock.io/e310dd11c1684dbab6a7f6a5f18d904a";

async function getSolInstructions(account, ReceiverAddress, amount) {
  const amountSol = new BigNumber(amount);
  return SystemProgram.transfer({
    fromPubkey: account,
    toPubkey: ReceiverAddress,
    lamports: amountSol
      .multipliedBy(LAMPORTS_PER_SOL)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toNumber(),
  });
}

async function getUsdcInstructions(account, ReceiverAddress) {
  const fromAddress = await getAssociatedTokenAddress(USDC_ADDRESS, account);

  const toAddress = await getAssociatedTokenAddress(USDC_ADDRESS, ReceiverAddress);

  return { fromAddress, toAddress };

  /* const decimals = 6;
  return createTransferCheckedInstruction(
    fromUsdcAddress,
    USDC_ADDRESS,
    toUsdcAddress,
    account,
    amount * 10 ** decimals,
    decimals
  ); */
}

async function getPyusdInstructions(account, ReceiverAddress) {
  const fromAddress = await getAssociatedTokenAddressSync(
    PYUSD_ADDRESS,
    account,
    false,
    TOKEN_2022_PROGRAM_ID
  );

  const toAddress = await getAssociatedTokenAddressSync(
    PYUSD_ADDRESS,
    ReceiverAddress,
    false,
    TOKEN_2022_PROGRAM_ID
  );

  return { fromAddress, toAddress };
}

export async function postImpl(accountI, PAY_CURRENCY, amount, address, memo = null) {
  console.log("🚀 ~ postImpl ~ address,memo:", address, memo);
  const account = new PublicKey(accountI || "Fdcz3gcweweK2WjE2zhWFKCVxFqEHCQ3NuGYEDsHFocx");
  const connection = new Connection(ENDPOINT);

  const ADDRESS = address || "od9jGcACCNaXdnomqk6GFYBD2aVtJqk4WNqBszZn4z9";
  

  let transaction = new Transaction();
  if (memo != null) {
    transaction.add(
      new TransactionInstruction({
        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        keys: [],
        data: Buffer.from(memo, "utf8"),
      })
    );
  }

  const ReceiverAddress = new PublicKey(ADDRESS);
  let instruction;
  if (PAY_CURRENCY === "USDC_SOL") {
    const { fromAddress, toAddress } = await getUsdcInstructions(account, ReceiverAddress, amount);

    // Add instruction to create recipient's associated token account if it doesn't exist
    const recipientATAAccountInfo = await connection.getAccountInfo(toAddress);
    if (!recipientATAAccountInfo) {
      const createATAInstruction = createAssociatedTokenAccountInstruction(
        account,
        toAddress,
        ReceiverAddress,
        USDC_ADDRESS,
      );

      transaction.add(createATAInstruction);
    }

    const decimals = 6;
    instruction = createTransferCheckedInstruction(
      fromAddress,
      USDC_ADDRESS,
      toAddress,
      account,
      amount * 10 ** decimals,
      decimals,
    );

  } else if (PAY_CURRENCY === "SOL") {
    instruction = await getSolInstructions(account, ReceiverAddress, amount);
  } else if (PAY_CURRENCY === "PYUSD_SOL") {
    const { fromAddress, toAddress } = await getPyusdInstructions(
      account,
      ReceiverAddress,
    );

    // Add instruction to create recipient's associated token account if it doesn't exist
    const recipientATAAccountInfo = await connection.getAccountInfo(toAddress);
    if (!recipientATAAccountInfo) {
      const createATAInstruction = createAssociatedTokenAccountInstruction(
        account,
        toAddress,
        ReceiverAddress,
        PYUSD_ADDRESS,
        TOKEN_2022_PROGRAM_ID
      );

      transaction.add(createATAInstruction);
    }

    const decimals = 6;
    instruction = createTransferCheckedInstruction(
      fromAddress,
      PYUSD_ADDRESS,
      toAddress,
      account,
      amount * 10 ** decimals,
      decimals,
      [],
      TOKEN_2022_PROGRAM_ID
    );
  }

  transaction.add(instruction);

  const recentBlockhash = await connection.getLatestBlockhash();
  transaction.recentBlockhash = recentBlockhash.blockhash;
  transaction.feePayer = account;

  const signedTransaction = await window.solana.signTransaction(transaction);
  const signature = await connection.sendRawTransaction(signedTransaction.serialize());
  await connection.confirmTransaction(signature);
  
  return signature;

  // const serializedTransaction = transaction.serialize({
  //   requireAllSignatures: false,
  // });

  // const base64 = serializedTransaction.toString("base64");

  // return {
  //   transaction: base64,
  //   message: "",
  // };
}
